export default {
  init() {
    // JavaScript to be fired on all pages

    // Fonts
    window.WebFontConfig = {
      custom: {
        families: ['Austin Web:n3,n2&display=swap'],
      },
      google: {
        families: ['Montserrat:300,600,700&display=swap'],
      },
    };
    (function(d) {
      var wf = d.createElement('script'), s = d.scripts[0];
      wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
      wf.async = true;
      s.parentNode.insertBefore(wf, s);
    })(document);

    // Make bootstrap collapse into an acoordion
    $(document).ready(function() {
      $('.collapse').on('shown.bs.collapse', function () {
          $(this).prev().addClass('active');
      });
      $('.collapse').on('hidden.bs.collapse', function () {
          $(this).prev().removeClass('active');
      });
    });

    // Load Video Modal
    jQuery(document).ready(function($) {
      // Get video src from the data-src on each button
      var $videoSrc;  
      $('.video-btn').click(function() {
          $videoSrc = $(this).data('src');
      });
      // Autoplay when modal is opened
      $('#videoModal').on('shown.bs.modal',function() {
      // set video src to autoplay and not to show related video
      $('#video').attr('src', $videoSrc + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0'); 
      })
      // Stop playing video when modal is closed
      $('#videoModal').on('hide.bs.modal',function() {
          $('#video').attr('src', $videoSrc); 
      })
      // Clear src data on close
      $('#videoModal').on('hidden.bs.modal',function(){
         $(this).find('iframe').html('');
         $(this).find('iframe').attr('src', '');
      });
    });

    // Load Love
    $('.mega-menu-popup').find('a').attr('data-toggle', 'modal');
    $('.mega-menu-popup').find('a').attr('data-target', '#lovemoreModal');

    // Sharing popup on blog single template
    $('.popup').click(function() {
      window.open($(this).attr('href'), 'popupWindow', 'height=450,width=760,resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0');
      return false;
    });


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

  },
};
