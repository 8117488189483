// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
//import home from './routes/home';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  //home,
});

// import the needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import specific icons
import { faFacebookF, faTiktok, faTwitter, faPinterestP, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

// add the imported icons to the library
library.add(faFacebookF, faTiktok, faTwitter, faPinterestP, faInstagram, faYoutube);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

// Load Events
jQuery(document).ready(() => routes.loadEvents());
